
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonCard,
  IonIcon,
  IonText,
  IonLabel,
  IonItem,
  IonBackButton,
  IonButtons,
  IonCardContent
} from '@ionic/vue';
import { notifications } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Notificaciones',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonCard,
    IonIcon,
    IonText,
    IonLabel,
    IonItem,
    IonBackButton,
    IonButtons,
    IonCardContent
  },
  setup(){
    return{
      notifications,
    }
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      isLoading: false,
      notif: [],
    }
  },
  mounted(){
    this.loadPage()
  },
  methods:{
    loadPage: async function(){
      this.isLoading = true

      const response = await this.axios.get(this.server + '/items/notificaciones?limit=1&access_token='+(this as any).$store.state.token)

      const items: any = response.data

      //Formatted date
      /*if( items.data.length > 0 ){
        items.data.forEach((item: any,index: number) => {
          items.data[index].creado = this.getFormatedDate(item.creado)
        })
      }*/

      this.notif = items
      
      this.isLoading = false
    },
    getFormatedDate: function (date: any){
      console.log(date)
      date = new Date(date)
      date = new Intl.DateTimeFormat('es-ES', {dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires'}).format(date)
      return date
    }
  }
});
