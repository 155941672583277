<template>
  <ion-page>
    <!-- HEADER -->
    <ion-header>
      <ion-toolbar color="dark">
        <!-- TITLE -->
        <ion-item class="page-title ion-no-padding" lines="none" color="dark">
          <ion-icon slot="start" size="small" :icon="notifications" color="dark"></ion-icon>
          <ion-label>
            <ion-text color="primary">
              <h2><b>Notificaciones</b></h2>
            </ion-text>
          </ion-label>
        </ion-item>
        <!-- BUTTONS -->
        <ion-buttons slot="start">
          <ion-back-button color="primary" @click="$router.back()" text=""></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    
    <ion-content>
      <div class="container">
        <section>
          <ion-card v-for="item in notif.data" :key="item.id">
            <ion-item lines="none"> 
              <ion-label>
                <h2><b>{{ item.titulo }}</b></h2>
                <span class="ion-hide-sm-up">{{ item.creado }}</span>
              </ion-label>
              <ion-label class="ion-text-end ion-hide-sm-down">
                <span>{{ item.creado }}</span>
              </ion-label>
            </ion-item>

            <ion-card-content>
              {{ item.mensaje }}
            </ion-card-content>
          </ion-card>
        </section>
      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonCard,
  IonIcon,
  IonText,
  IonLabel,
  IonItem,
  IonBackButton,
  IonButtons,
  IonCardContent
} from '@ionic/vue';
import { notifications } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Notificaciones',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonCard,
    IonIcon,
    IonText,
    IonLabel,
    IonItem,
    IonBackButton,
    IonButtons,
    IonCardContent
  },
  setup(){
    return{
      notifications,
    }
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      isLoading: false,
      notif: [],
    }
  },
  mounted(){
    this.loadPage()
  },
  methods:{
    loadPage: async function(){
      this.isLoading = true

      const response = await this.axios.get(this.server + '/items/notificaciones?limit=1&access_token='+(this as any).$store.state.token)

      const items: any = response.data

      //Formatted date
      /*if( items.data.length > 0 ){
        items.data.forEach((item: any,index: number) => {
          items.data[index].creado = this.getFormatedDate(item.creado)
        })
      }*/

      this.notif = items
      
      this.isLoading = false
    },
    getFormatedDate: function (date: any){
      console.log(date)
      date = new Date(date)
      date = new Intl.DateTimeFormat('es-ES', {dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires'}).format(date)
      return date
    }
  }
});
</script>